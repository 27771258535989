var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑sku与资源映射' : '新增sku与资源映射',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"800px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',[_vm._v("基本信息")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"sku code"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'skuCode',
          {
            initialValue: _vm.record.skuCode || '',
            rules: [
              { required: true, validator: _vm.checkName, trigger: 'blur' } ],
          } ]),expression:"[\n          'skuCode',\n          {\n            initialValue: record.skuCode || '',\n            rules: [\n              { required: true, validator: checkName, trigger: 'blur' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请输入","maxLength":30}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('skuCode'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            initialValue: _vm.record.description || '',
          } ]),expression:"[\n          'description',\n          {\n            initialValue: record.description || '',\n          },\n        ]"}],attrs:{"rows":3,"placeholder":"请输入","maxLength":200}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('description'))+"/200")])],1)],1),_c('div',[_vm._v("资源设置")]),_c('a-table',{staticStyle:{"margin-top":"15px"},attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false,"rowKey":function (record) { return record.type; },"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    }},scopedSlots:_vm._u([{key:"type",fn:function(text, record){return _c('div',{},[_vm._v(" "+_vm._s(_vm.resourceTypeData[record.type].name)+" ")])}},{key:"quantity",fn:function(text, record){return _c('div',{staticClass:"input_box"},[_c('a-input',{attrs:{"placeholder":"请填写数量","disabled":!_vm.selectedRowKeys.includes(record.type) || record.disabled},model:{value:(record.quantity),callback:function ($$v) {_vm.$set(record, "quantity", $$v)},expression:"record.quantity"}}),_c('span',{staticStyle:{"width":"40px","text-align":"right"}},[_vm._v(_vm._s(record.quantityUnit))])],1)}},{key:"duration",fn:function(text, record){return _c('div',{staticClass:"input_box"},[_c('a-input',{attrs:{"placeholder":"请填写","disabled":!_vm.selectedRowKeys.includes(record.type)},model:{value:(record.duration),callback:function ($$v) {_vm.$set(record, "duration", $$v)},expression:"record.duration"}}),_c('span',{staticStyle:{"width":"40px","text-align":"right"}},[_vm._v("天")])],1)}}])},[_c('span',{staticClass:"title_box",attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('span',[_vm._v("有效时长")]),_c('span',[_c('a-checkbox',{on:{"change":_vm.onChange}},[_vm._v(" 统一设为365天 ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }