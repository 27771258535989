<template>
  <a-modal
    :title="isEdit ? '编辑sku与资源映射' : '新增sku与资源映射'"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="800px"
  >
    <div>基本信息</div>
    <a-form :form="form">
      <a-form-item label="sku code">
        <a-input
          v-decorator="[
            'skuCode',
            {
              initialValue: record.skuCode || '',
              rules: [
                { required: true, validator: checkName, trigger: 'blur' },
              ],
            },
          ]"
          placeholder="请输入"
          :maxLength="30"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('skuCode') }}/30</span
        >
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea
          :rows="3"
          placeholder="请输入"
          :maxLength="200"
          v-decorator="[
            'description',
            {
              initialValue: record.description || '',
            },
          ]"
        ></a-textarea>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('description') }}/200</span
        >
      </a-form-item>
    </a-form>
    <div>资源设置</div>
    <a-table
      style="margin-top: 15px"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :rowKey="(record) => record.type"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="customTitle" class="title_box">
        <span>有效时长</span>
        <span><a-checkbox @change="onChange"> 统一设为365天 </a-checkbox></span>
      </span>
      <div slot="type" slot-scope="text, record">
        <!-- {{ typeName[record.type] }} -->
        {{ resourceTypeData[record.type].name }}
      </div>
      <!-- 资源数量可填 -->
      <div slot="quantity" slot-scope="text, record" class="input_box">
        <a-input
          placeholder="请填写数量"
          v-model="record.quantity"
          :disabled="!selectedRowKeys.includes(record.type) || record.disabled"
        ></a-input>
        <span style="width: 40px; text-align: right">{{
          record.quantityUnit
        }}</span>
      </div>
      <!-- 有效时长可填 -->
      <div slot="duration" slot-scope="text, record" class="input_box">
        <a-input
          placeholder="请填写"
          v-model="record.duration"
          :disabled="!selectedRowKeys.includes(record.type)"
        ></a-input
        ><span style="width: 40px; text-align: right">天</span>
      </div>
    </a-table>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: '资源类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '资源数量',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' },
    width: 230,
  },

  {
    dataIndex: '有效时长',
    key: 'duration',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'duration' },
  },
]

export default {
  name: 'AddIntegration',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this, {}),
      isEdit: Object.keys(this.record).length > 0,
      columns,
      // data: [
      //   {
      //     id: '1',
      //     type: 'COMPANY_PEOPLE',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '人',
      //   },
      //   {
      //     id: '2',
      //     type: 'BIGTABLE_FORM_APPLICATION',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '3',
      //     type: 'BIGTABLE_PROCESS_APPLICATION',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '4',
      //     type: 'BIGTABLE_PORTAL_APPLICATION',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '5',
      //     type: 'BIGTABLE_SCREEN_APPLICATION',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '6',
      //     type: 'USAGE_SMS',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '条',
      //   },
      //   {
      //     id: '7',
      //     type: 'USAGE_STORAGE',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: 'GB',
      //   },
      //   {
      //     id: '8',
      //     type: 'USAGE_DOWNLOAD',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: 'GB',
      //   },
      //   {
      //     id: '9',
      //     type: 'BUSINESS_CUSTOMER',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '10',
      //     type: 'BUSINESS_OPPORTUNITY',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '11',
      //     type: 'BUSINESS_SHOP',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '12',
      //     type: 'BUSINESS_CONTENT',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '13',
      //     type: 'BUSINESS_MEMBERSHIP',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '14',
      //     type: 'BUSINESS_FINANCE',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },

      //   {
      //     id: '15',
      //     type: 'BUSINESS_MARKETING',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '16',
      //     type: 'BUSINESS_SUPPLY',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      //   {
      //     id: '17',
      //     type: 'BUSINESS_WAREHOUSE',
      //     quantity: '1',
      //     duration: '0',
      //     durationUnit: 'DAYS',
      //     quantityUnit: '个',
      //   },
      // ],
      data: [],
      selectedRowKeys: [],
      typeName: {
        COMPANY_PEOPLE: '用户数',
        BIGTABLE_FORM_APPLICATION: '表单应用',
        BIGTABLE_PROCESS_APPLICATION: '流程应用',
        BIGTABLE_PORTAL_APPLICATION: '移动门户应用',
        BIGTABLE_SCREEN_APPLICATION: '大屏应用',
        USAGE_SMS: '短信',
        USAGE_STORAGE: '存储容量',
        USAGE_DOWNLOAD: '流量',
        BUSINESS_CUSTOMER: '客户关系',
        BUSINESS_OPPORTUNITY: '商机立项',
        BUSINESS_SHOP: '店铺管理',
        BUSINESS_CONTENT: '内容管理',
        BUSINESS_MEMBERSHIP: '会员',
        BUSINESS_FINANCE: '财务',
        BUSINESS_MARKETING: '渠道营销',
        BUSINESS_SUPPLY: '供应链',
        BUSINESS_WAREHOUSE: '仓库管理',
      },
      resourceTypeData: {},
    }
  },
  // computed: {
  //   getItemValueLength() {
  //     return function (val) {
  //       const value = this.form.getFieldsValue([val])[val]
  //       console.log(value)
  //       return value ? value.length : '0'
  //     }
  //   },
  // },
  async created() {
    // console.log(this.isEdit, this.record)
    await this.init()
    this.$nextTick(() => {
      this.$forceUpdate()
      if (this.isEdit) {
        this.selectedRowKeys = this.record.resources.map((item) => item.type)
      }
    })
  },
  methods: {
    /* 获取资源配置项信息 */
    getResourceTypeInfo() {
      return new Promise((resolve, reject) => {
        this.$api.getResourceTypes().then((res) => {
          if (res.code === 0) {
            this.resourceTypeData = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async init() {
      /* 资源类型获取 */
      await this.getResourceTypeInfo().then(() => {
        let resourceTypeKey = Object.keys(this.resourceTypeData)
        this.data = resourceTypeKey.map((item, index) => {
          return {
            // id: index + 1,
            type: item,
            quantity: '1',
            quantityUnit: this.resourceTypeData[item].unit,
            duration: '0',
            durationUnit: 'DAYS',
          }
        })
      })
      if (this.isEdit) {
        // console.log(this.selectedRowKeys)
        // this.data = this.record.resources
        /* 资源配置回显赋值 */
        this.data.forEach((item, index) => {
          this.record.resources.forEach((_item, _index) => {
            if (_item.type == item.type) {
              this.data[index] = _item
            }
          })
        })
      }
      /* 只能设置时间的资源配置项 */
      let disabledArr = Object.keys(this.resourceTypeData).filter((item) => {
        return item.split('_')[0] === 'DURATION'
      })
      console.log(disabledArr)
      // let disabledArr = [
      //   'DURATION_BASE_SOLUTION_CUSTOMER',
      //   'DURATION_BASE_SOLUTION_FINANCE',
      //   'DURATION_BASE_SOLUTION_MARKETING',
      //   'DURATION_BASE_SOLUTION_MEMBERSHIP',
      //   'DURATION_BASE_SOLUTION_OPPORTUNITY',
      //   'DURATION_BASE_SOLUTION_SHOP',
      //   'DURATION_BASE_SOLUTION_SUPPLY',
      //   'DURATION_BASE_SOLUTION_WAREHOUSE',
      //   'DURATION_BASE_SOLUTION_CONTENT',
      // ]
      this.data.forEach((item, index) => {
        // this.$set(item, 'id', index + 1)
        if (disabledArr.includes(item.type)) {
          this.$set(item, 'disabled', true)
        }
      })
    },
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    /* 校验名称时候重复 */
    checkName(rule, value, callback) {
      if (value) {
        // if (!alreadyName.includes(value) || value == record.name) {
        //   return Promise.resolve('')
        // } else {
        //   return Promise.reject('sku已存在，请重新输入')
        // }
        return Promise.resolve()
      } else {
        return Promise.reject('请输入sku')
      }
    },
    /* 添加资源封装 */
    addIntegration(data) {
      return new Promise((resolve, reject) => {
        this.$api.createdIntegration(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '添加资源封装')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改资源封装 */
    updateIntegration(bindingId, data) {
      return new Promise((resolve, reject) => {
        this.$api.updateIntegration(bindingId, data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改资源封装')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    handleOk() {
      // this.confirmLoading = true
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values, this.data)

          let _data = this.data.filter((item) =>
            this.selectedRowKeys.includes(item.type)
          )
          let isError = _data.every((item) => {
            return item.quantity > 0 && item.duration > 0
          })
          // console.log(isError)
          if (!isError) {
            this.$message.error('请检查资源设置输入值是否>1')
            return
          }
          if (this.isEdit) {
            /* 修改 */
            let { id, activated } = this.record

            let data = {
              ...values,
              activated,
              resources: _data,
            }
            this.updateIntegration(id, data).then(() => {
              this.$message.success('修改成功')
              this.$emit('refresh')
            })
          } else {
            /* 新增 */
            this.$set(values, 'activated', true)
            this.$set(values, 'resources', _data)
            this.addIntegration(values).then(() => {
              this.$emit('refresh')
            })
          }
        }
      })
      // setTimeout(() => {
      //   this.confirmLoading = false
      //   this.$emit('refresh')
      // }, 2000)
    },
    handleCancel() {
      this.$emit('refresh')
      // this.$emit('update:visible', false)
    },
    /* 统一设置checkbox */
    onChange({ target }) {
      // console.log(target)
      if (target.checked) {
        this.data.forEach((item) => {
          if (this.selectedRowKeys.includes(item.type)) {
            item.duration = 365
          }
        })
      } else {
        this.data.forEach((item) => {
          if (this.selectedRowKeys.includes(item.type)) {
            item.duration = ''
          }
        })
      }
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .img {
  width: 102px;
  height: 102px;
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

/deep/ .ant-table-header-column {
  width: 100%;
}

/deep/ .input_double {
  display: flex;
  align-items: center;
}

.title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
