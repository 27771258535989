<template>
  <div class="integration">
    <a-card>
      <div style="margin-bottom: 10px">
        <a-button type="primary" icon="plus" @click="openAddIntegration"
          >新增</a-button
        >
      </div>
      <!-- 资源封装列表 -->
      <a-table
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.skuId"
        :pagination="false"
      >
        <div slot="resourceConfig" slot-scope="text, record">
          <span v-for="item in record.resources" :key="item.type">
            {{ resourceTypeData[item.type].name }}: {{ item.quantity
            }}{{ item.quantityUnit }} ;
          </span>
        </div>
        <span slot="activated" slot-scope="text, record">
          <a-switch
            checked-children="开"
            un-checked-children="关"
            :defaultChecked="record.activated"
            @change="switchChange($event, record)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="edit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="del(record)">删除</a>
        </span>
      </a-table>
      <add-integration
        v-if="visible"
        :record="record"
        :visible.sync="visible"
        @refresh="refresh"
      ></add-integration>
    </a-card>
  </div>
</template>

<script>
import addIntegration from './modal/addIntegration.vue'

const columns = [
  {
    title: 'sku code',
    dataIndex: 'skuCode',
    key: 'skuCode',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'skuCode' },
    width: 180,
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
  },

  {
    title: '资源配置',
    dataIndex: 'resourceConfig',
    key: 'resourceConfig',
    scopedSlots: { customRender: 'resourceConfig' },
  },
  {
    title: '状态',
    dataIndex: 'activated',
    key: 'activated',
    scopedSlots: { customRender: 'activated' },
  },
  {
    title: '最近修改时间',
    dataIndex: 'lastModifiedDate',
    key: 'lastModifiedDate',
    scopedSlots: { customRender: 'lastModifiedDate' },
    sorter: (a, b) =>
      new Date(a.lastModifiedDate) - new Date(b.lastModifiedDate),
    width: 200,
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 150,
  },
]

export default {
  name: 'ResourceIntegration',
  components: { addIntegration },
  data() {
    return {
      columns,
      data: [],
      visible: false,
      record: {},
      // typeName: {
      //   COMPANY_PEOPLE: '用户数',
      //   BIGTABLE_FORM_APPLICATION: '表单应用',
      //   BIGTABLE_PROCESS_APPLICATION: '流程应用',
      //   BIGTABLE_PORTAL_APPLICATION: '移动门户应用',
      //   BIGTABLE_SCREEN_APPLICATION: '大屏应用',
      //   USAGE_SMS: '短信',
      //   USAGE_STORAGE: '存储容量',
      //   USAGE_DOWNLOAD: '流量',
      //   BUSINESS_CUSTOMER: '客户关系',
      //   BUSINESS_OPPORTUNITY: '商机立项',
      //   BUSINESS_SHOP: '店铺管理',
      //   BUSINESS_CONTENT: '内容管理',
      //   BUSINESS_MEMBERSHIP: '会员',
      //   BUSINESS_FINANCE: '财务',
      //   BUSINESS_MARKETING: '渠道营销',
      //   BUSINESS_SUPPLY: '供应链',
      //   BUSINESS_WAREHOUSE: '仓库管理',
      // },
      resourceTypeData: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    /* 获取资源配置项信息 */
    getResourceTypeInfo() {
      return new Promise((resolve, reject) => {
        this.$api.getResourceTypes().then((res) => {
          if (res.code === 0) {
            this.resourceTypeData = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    init() {
      this.getResourceTypeInfo()
      this.getResourceIntegrationList()
    },
    openAddIntegration() {
      this.record = {}
      this.visible = true
    },
    refresh() {
      this.visible = false
      this.record = {}
      this.init()
    },
    edit(record) {
      this.record = record
      this.visible = true
    },
    del(record) {
      // console.log(record)
      let { id } = record
      let _this = this
      this.$confirm({
        title: '确定删除该条?',
        content: (h) => (
          <div style="color:red;">删除后不可恢复，请谨慎操作</div>
        ),
        onOk() {
          // console.log('OK')
          _this.delResourceIntegration(id).then(() => {
            _this.$message.success('删除成功')
            _this.init()
          })
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
    /* 状态开关 */
    switchChange(e, record) {
      // console.log(e, record)
      let { id, skuCode, resources, description } = record
      let data = {
        skuCode,
        resources,
        description,
        activated: e,
      }

      this.updateResourceIntegration(id, data).then(() => {
        this.$message.success('修改成功')
        this.init()
      })
    },
    /* 删除资源封装 */
    delResourceIntegration(bindingId) {
      return new Promise((resolve, reject) => {
        this.$api.deleteIntegration(bindingId).then((res) => {
          if (res.code === 0) {
            // console.log(res, '删除资源封装')
            resolve()
          } else {
            this.$message.error('删除失败')
            reject()
          }
        })
      })
    },
    /* 修改、更新资源封装 */
    updateResourceIntegration(bindingId, data) {
      return new Promise((resolve, reject) => {
        this.$api.updateIntegration(bindingId, data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改资源封装')
            resolve()
          } else {
            this.$message.error('修改失败')
            reject()
          }
        })
      })
    },
    /* 获取资源封装列表 */
    getResourceIntegrationList() {
      return new Promise((resolve, reject) => {
        this.$api.getIntegrationList().then((res) => {
          if (res.code === 0) {
            // console.log(res, '封装列表')
            this.data = res.data.content
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.integration {
  min-height: 99%;
  background: #fff;
}

/deep/ .ant-card-bordered {
  border-bottom: none;
}
</style>
